import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

interface Props {
  children?: any;
}

const Minmial = (props: Props): ReactElement => {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.root}>
      {children}
      {/* <Copyright /> */}
    </div>
  );
};

const defaultProps: Props = {};

Minmial.defaultProps = defaultProps;

export default Minmial;
