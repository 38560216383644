import { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { LoadingScreen } from 'components';
import { pages, routes } from './constant/routes';
import { paths } from './constant/values';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={paths.home} />

      <Suspense fallback={<LoadingScreen />}>
        {routes.map((route) => (
          <RouteWithLayout
            key={route.key}
            component={route.component}
            exact={route.exact}
            layoutTitle={route.title}
            layout={route.layout}
            path={route.path}
            isProtected={route.isProtected}
          />
        ))}
      </Suspense>
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
