import React, { useContext, useEffect } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Icon } from 'components';
import { useCategoires, useItems, useStore } from 'hooks';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getDeviceInfo } from 'utils/device';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: theme.spacing(-0.3),
  },
  categories: {
    minHeight: 90,
  },
  centeredCategoires: {
    display: 'flex',
    justifyContent: 'center',
  },
  category: {
    fontWeight: 500,
    fontSize: 15,
    borderRadius: 10,
    minHeight: 40,
  },
  categoryRTL: {
    marginLeft: theme.spacing(3),
  },
  categoryLTR: {
    marginRight: theme.spacing(3),
  },
  categoiresWithLargeScreen: {
    marginBottom: theme.spacing(2),
  },
  categoryWithLargeScreen: {
    fontWeight: 'bold',
  },
  appBar: {
    backgroundColor: 'transparent',
    overflow: 'scroll',
  },
}));
//const screenWidth = window.innerWidth;
//const phoneWidth = 500;
//const isPhone = screenWidth <= phoneWidth;
//const isDesktop = !isPhone;
const { isDesktop, isPhone } = getDeviceInfo();
function Categories(props: any) {
  const classes = useStyles();
  const { categories } = useCategoires();
  const { isCategorySelected, handleCategoryClick } = useCategoires();
  const { t, i18n } = useTranslation();
  const dir = i18n.dir();
  function LeftArrow() {
    const { scrollPrev, scrollNext } = React.useContext(VisibilityContext);
    if (isPhone) return null;
    if (categories.length <= 5 && isDesktop) return null;
    return (
      <div className={classes.icon}>
        <Icon
          icon={'left'}
          onClick={() => (dir === 'ltr' ? scrollPrev() : scrollNext())}
        />
      </div>
    );
  }
  function RightArrow() {
    const { scrollNext, scrollPrev } = React.useContext(VisibilityContext);
    if (isPhone) return null;
    if (categories.length <= 5 && isDesktop) return null;
    return (
      <div className={classes.icon}>
        <Icon
          icon={'right'}
          onClick={() => (dir === 'ltr' ? scrollNext() : scrollPrev())}
        />
      </div>
    );
  }
  function Category(props: { title: string; itemId: string }) {
    const { title, itemId } = props;
    return (
      <Chip
        className={clsx(classes.category, {
          [classes.categoryRTL]: dir === 'rtl',
          [classes.categoryLTR]: dir === 'ltr',
        })}
        clickable
        size="small"
        label={title}
        color={isCategorySelected(itemId) ? 'primary' : 'default'}
        onClick={() => handleCategoryClick(itemId)}
      />
    );
  }

  return (
    <div dir={dir}>
      <ScrollMenu
        LeftArrow={dir === 'ltr' ? LeftArrow : RightArrow}
        RightArrow={dir === 'ltr' ? RightArrow : LeftArrow}
        scrollContainerClassName={clsx(classes.categories, {
          [classes.centeredCategoires]:
            (categories.length <= 5 && isDesktop) || categories.length <= 2,
        })}
      >
        {categories.map(({ id, name }) => (
          <Category
            itemId={id} // NOTE: itemId is required for track items
            title={name}
            key={id}
          />
        ))}
      </ScrollMenu>
    </div>
  );
}

export default Categories;
