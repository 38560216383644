import React, { forwardRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Icon } from 'components';
import { NavLink } from 'react-router-dom';
import { iconList } from 'components/props';
import { paths } from 'constant/values';

const CustomRouterLink = forwardRef((props: any, ref: any) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <NavLink {...props} />
  </div>
));

type sideBarItems = { text: string; path: string; icon: iconList }[];

const items: sideBarItems = [
  {
    text: 'Dashboard',
    path: paths.dashboard,
    icon: 'Dashboard',
  },
  {
    text: 'Store',
    path: paths.store,
    icon: 'Storefront',
  },
  {
    text: 'Settings',
    path: paths.settings,
    icon: 'Settings',
  },
];

export const mainListItems = (
  <div>
    {items.map((sidebar, index) => {
      return (
        <ListItem
          button
          component={CustomRouterLink}
          to={sidebar.path}
          key={index}
        >
          <ListItemIcon>
            <Icon icon={sidebar.icon} />
          </ListItemIcon>
          <ListItemText primary={sidebar.text} />
        </ListItem>
      );
    })}

    {/* <ListItem button>
      <ListItemIcon>
        <Icon icon="Storefront" />
      </ListItemIcon>
      <ListItemText primary="Store" />
    </ListItem>

    <ListItem button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItem>

    <ListItem button>
      <ListItemIcon>
        <Icon icon="People" />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItem>

    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem> */}
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
