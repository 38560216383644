import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import ScrollToTop from 'react-scroll-to-top';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: '50%',
    //right: theme.spacing(2),
  },
  iconContainer: {
    position: 'fixed',
    bottom: theme.spacing(5),
    background: 'none',
    left: 27,
    border: 'none',
  },
  icon: {
    opacity: 0.2,
  },
}));

function ScrollTop(props: any) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 600,
  });

  const handleClick = (event: any) => {
    const anchor = document.querySelector('#back-to-top-anchor-12333333');

    if (anchor) {
      anchor.scrollIntoView(true);
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function BackToTop(props: any) {
  const classes = useStyles();

  return (
    <ScrollToTop
      smooth
      top={1000}
      // style={{
      //   background: 'none',
      //   backgroundColor: 'none',
      // }}
      className={classes.iconContainer}
      component={
        <Fab
          className={classes.icon}
          color="secondary"
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      }
    />
  );
}
