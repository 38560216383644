import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';
import { Button, Switch, ThemeProvider } from '@material-ui/core';
import { darkTheme, lightTheme } from 'theme';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import TranslateIcon from '@material-ui/icons/Translate';
import LanguageIcon from '@material-ui/icons/Language';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from 'constant/translations';
import { WebsiteContext, ViewTypeContext } from 'context';
import { useWebsite, useTheme } from 'hooks';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import 'app.css';
import CssBaseline from '@material-ui/core/CssBaseline';

const browserHistory = createBrowserHistory();
const App = () => {
  const savedLangugae = localStorage.getItem('lang');
  const lang = savedLangugae || 'ar';
  type UserView = 'small' | 'default';
  //@ts-ignore
  const savedViewType: UserView = localStorage.getItem('viewType') || 'small';
  const [viewType, setViewType] = useState<UserView>(savedViewType);
  const { website, selectedCategoryId, setSelectedCategoryId } = useWebsite();

  i18n.use(initReactI18next).init(translations);
  i18n.changeLanguage(lang);
  const { theme, isDark, toggleTheme } = useTheme(website.store.theme);
  useEffect(() => {
    localStorage.setItem('viewType', viewType);
  }, [viewType]);
  const languageHandler = () => {
    const newLang = lang === 'en' ? 'ar' : 'en';
    localStorage.setItem('lang', newLang);
    window.location.reload();
    i18n.changeLanguage(newLang).then(() => {});
  };
  return (
    <Router history={browserHistory}>
      <WebsiteContext.Provider
        value={{ ...website, selectedCategoryId, setSelectedCategoryId }}
      >
        <ViewTypeContext.Provider value={viewType}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <div style={{ position: 'absolute', right: '1rem', top: '1rem' }}>
                <Button onClick={languageHandler}>
                  {lang === 'en' ? 'عربي' : 'English'}
                </Button>
                <Button
                  onClick={() =>
                    setViewType(viewType === 'small' ? 'default' : 'small')
                  }
                >
                  <FormatSizeIcon />
                </Button>

                {isDark ? (
                  <Button onClick={toggleTheme}>
                    <Brightness7Icon
                      style={{ width: '2rem', height: '2rem' }}
                    />
                  </Button>
                ) : (
                  <Button onClick={toggleTheme}>
                    <Brightness4Icon
                      style={{ width: '2rem', height: '2rem' }}
                    />
                  </Button>
                )}
              </div>
              <Routes />
            </SnackbarProvider>
          </ThemeProvider>
        </ViewTypeContext.Provider>
      </WebsiteContext.Provider>
    </Router>
  );
};

export default App;
