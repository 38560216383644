import { NameDB, Res, Functions } from 'interfaces';
import firebase from 'firebase-app';
export const db = (dbName: NameDB) => {
  const collectionName = dbName.collection;
  return firebase.firestore().collection(collectionName);
};

export const generateDocumentId = (dbName: NameDB) => {
  return db(dbName).doc().id;
};

export const getCurrentUser = () => firebase.auth().currentUser;

export const functions = async <dataRES = Res, msg = string>(
  targetFunction: Functions
) => {
  const name = targetFunction.function;
  const options = targetFunction.options;
  const data = targetFunction.data;

  const result = await firebase.functions().httpsCallable(name, options)(data);
  console.log('func result', result);
  const response: dataRES = result.data;
  return response;
};
