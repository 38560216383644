import { storeValues } from 'constant/values';
import { createTimestamp } from 'utils/dates';
import { Store } from 'interfaces';
import { name, subscription, description } from 'validations/default-values';
import { defaultTheme } from '../store-selected';

const store: Store = {
  name: name,
  storeId: '',
  theme: defaultTheme,
  type: ['coffee'],
  logo: {
    public_id: '',
    url: '',
  },
  currency: 'SAR',
  websiteConfig: {},
  categories: [],
  lastSignedIn: createTimestamp(),
  createdAt: createTimestamp(),
  employees: 1,
  phone: '',
  branches: 0,
  subscription: subscription,
  isActive: true,
  rating: 0,
  description: description,
  socialLinks: {},
};

export default store;
