import App from 'App';
import { Component } from 'react';
import { Minmial } from 'layout';
import { Home } from '../../pages';
import { paths } from '../values';

interface Route {
  key: string;
  title: string;
  path: string;
  component: Component | Function;
  layout: any;
  exact: boolean;
  isProtected: boolean;
}

var routes: Array<Route> = [];

const pages = {
  app: {
    title: 'vendor',
    path: paths.DOMAIN || '',
    component: App,
    layout: Minmial,
    exact: true,
    isProtected: false,
  },
  home: {
    title: 'Home',
    path: paths.home,
    component: Home,
    layout: Minmial,
    exact: true,
    isProtected: true,
  },
};
for (const [key, value] of Object.entries(pages)) {
  routes.push({ key, ...value });
}

export { pages, routes };
