import { userValues } from 'constant/values';
import { createTimestamp } from 'utils/dates';
import { User } from 'interfaces';
import name from '../name';
//import {User} from 'interfaces'
const accountTypeValues = userValues.accountTypeValues;

const user: User = {
  name: name,
  userObjectId: '',
  phone: '',
  docId: '',
  createdAt: createTimestamp(),
  isVerified: false,
  email: '',
  lastSignedIn: createTimestamp(),
  accountType: ['business'],
  storesAccess: [],
};
export default user;
