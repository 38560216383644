import { useState, useEffect } from 'react';
import { WebsiteContextInt } from 'interfaces/website-context';
import { db } from 'services/common';
import { sotreWebDB } from 'config/db';
import produce from 'immer';
import { defaultWebsite } from 'validations/default-values';
//@ts-ignore
import queryString from 'query-string';
import _ from 'lodash';
import { getStoreIdFromURL } from 'services/store/utils';
const useWebsite = () => {
  const [value, setValue] = useState<WebsiteContextInt>(defaultWebsite);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  const storeId = getStoreIdFromURL();

  const updateState = (callback: (draftCopy: WebsiteContextInt) => void) => {
    const copy = produce(value, (draft) => callback(draft));
    setValue(copy);
    return copy;
  };
  const getWebsite = async () => {
    const ref = await db(sotreWebDB)
      .where('queries', 'array-contains', storeId || '')
      .get();
    if (ref.docs.length > 0) {
      //@ts-ignore
      const data: WebsiteContextInt = ref.docs[0].data();
      console.log('data', data);
      const update = (draft: WebsiteContextInt) => {
        draft.store = data.store;
        draft.categories = data.categories;
        draft.items = data.items;
        const test = data.items.map(
          (item) => `${item.categoryIndex}-${item.itemIndex}`
        );

        console.log('test', test);
      };

      return updateState(update);
    }
  };
  const handleCategorySelectedChange = (categoryId: string) => {
    setSelectedCategoryId(categoryId);
  };
  useEffect(() => {
    getWebsite();
  }, []);
  return {
    website: value,
    selectedCategoryId,
    setSelectedCategoryId: handleCategorySelectedChange,
  };
};
export default useWebsite;
