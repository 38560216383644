import {
  changeDefaultValue,
  getTranslation,
  changeTranslation,
  //handelImagesDeleteAndSave
} from 'utils/name-with-translation/index';
import produce from 'immer';
import { getUserLanguage } from 'utils/language';
import { Name } from 'interfaces/common';
import { name } from 'validations/default-values';

const useName = (TranslationObject: Name = name) => {
  const userLanguage = getUserLanguage();

  const onTranslationChange = (newValue: string, langauge?: any) => {
    const copy = produce(TranslationObject, (draft) => {
      changeTranslation(draft, newValue, langauge);
    });
    //setValue(copy);
    return copy;
  };

  const getTranslationValue = (language: string) => {
    return getTranslation(TranslationObject, language, true);
  };

  const isDefaultLangugae = (targetLanguage: string) => {
    const { language, languageCode } = TranslationObject.defaultLanguage;
    if (language === targetLanguage) return true;
    if (languageCode === targetLanguage) return true;
    return false;
  };

  const changeDefaultLangauge = (currentLanguage: string) => {
    const copy = produce(TranslationObject, (draft) => {
      return changeDefaultValue(draft, currentLanguage);
    });
    //setValue(copy);
    return copy;
  };

  const getAnyTranslationValue = (language = userLanguage) => {
    return getTranslation(TranslationObject, language, true);
  };

  const getState = () => TranslationObject;

  return {
    onTranslationChange,
    getTranslationValue,
    getAnyTranslationValue,
    isDefaultLangugae,
    changeDefaultLangauge,
    getState,
  };
};

export default useName;
