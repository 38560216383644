import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Item } from 'interfaces';
import { useName } from 'hooks';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import clsx from 'clsx';
import './card.css';
import { imageLarge, imageSmall } from 'config';
import { getCloudinaryImageURL } from 'utils/image';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    borderRadius: theme.spacing(1),
    height: '100%',
  },
  cardMediaLarge: {
    //aspectRatio: '1.5/1',
    height: imageLarge.thumb.height,
  },
  cardMediaSmall: {
    height: imageSmall.thumb.height,
  },
  img: {
    width: '100%',
  },
  smallImg: {
    height: imageSmall.thumb.height,
  },
  largeImg: {
    height: imageLarge.thumb.height,
  },
  cardContent: {
    marginTop: theme.spacing(-1.5),
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    fontWeight: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  numbersContainer: {
    marginBottom: theme.spacing(-1),
  },
  storeLogo: {
    width: '10rem',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  price: {
    fontWeight: 700,
  },
  actions: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

interface CardWithImageProps {
  card: Item;
  onClick: (item: Item) => void;
  viewType: 'small' | 'default';
}

const CardItem = (props: CardWithImageProps) => {
  const { card, viewType, onClick } = props;
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const direction = i18n.dir();
  const description = useName(card.description).getTranslationValue(
    i18n.language
  );
  const hasPrice = card.price > 0;
  const Price = () => {
    if (hasPrice)
      return (
        <Grid item>
          <Typography
            //gutterBottom
            className={classes.price}
            variant={viewType === 'small' ? 'subtitle2' : 'subtitle1'}
          >
            {card.currency} {card.price}{' '}
          </Typography>
        </Grid>
      );

    return null;
  };
  const Description = () => {
    if (_.isEmpty(description) || viewType === 'small') return null;

    return (
      <Grid item xs={12}>
        <Typography className={classes.description} variant="subtitle2">
          {description}
        </Typography>
      </Grid>
    );
  };
  const Name = () => {
    return (
      <Grid item xs={viewType === 'small' || !hasPrice ? 12 : 9}>
        <Typography
          gutterBottom
          variant={viewType === 'small' ? 'subtitle1' : 'h6'}
        >
          {useName(card.name).getTranslationValue(i18n.language)}
        </Typography>
      </Grid>
    );
  };
  const Calories = () => {
    if (viewType === 'small') return null;
    return (
      <Grid item>
        {card.information.calories <= 0 ? null : (
          <Typography variant="caption">
            {t('calories')}: {card.information.calories}
          </Typography>
        )}
      </Grid>
    );
  };
  return (
    <Card dir={direction} className={clsx(classes.card, 'grow')}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        style={{ height: '100%', maxHeight: 500 }}
      >
        <CardActionArea onClick={() => onClick(card)}>
          {/* <CardMedia
          // className={clsx({
          //   [classes.cardMediaLarge]: viewType === 'default',
          //   [classes.cardMediaSmall]: viewType === 'small',
          // })}
          >
            <CloudinaryImage
              public_Id={card.images[0]?.public_id}
              height={
                viewType === 'small'
                  ? imageSmall.thumb.height
                  : imageLarge.thumb.height
              }
              width={
                viewType === 'small'
                  ? imageSmall.thumb.width
                  : imageLarge.thumb.width
              }
            />
          </CardMedia> */}
          <CardMedia
            image={getCloudinaryImageURL(card.images[0]?.public_id)}
            className={clsx({
              [classes.cardMediaLarge]: viewType === 'default',
              [classes.cardMediaSmall]: viewType === 'small',
            })}
          />

          <CardContent className={clsx(classes.cardContent)}>
            <Grid container justifyContent="space-between">
              <Name />
              {viewType === 'small' ? null : <Price />}
              <Description />
            </Grid>
          </CardContent>
        </CardActionArea>
        <Grid item className={classes.actions}>
          {viewType === 'small' ? <Price /> : null}
          <Calories />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardItem;
