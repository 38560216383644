import { WebsiteContext } from 'context';
import transilation from 'hooks/name';
import _ from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
const useStore = () => {
  const { store, categories } = useContext(WebsiteContext);
  const [t, i18n] = useTranslation();
  const lang = i18n.language;

  const getCategories = () => {
    let categoryTest = categories.map((category) => {
      const name = transilation(category.name).getTranslationValue(lang);
      return {
        id: category.id,
        name: name,
      };
    });
    return categoryTest;
  };
  const name = () => transilation(store.name).getTranslationValue(lang);
  const description = () =>
    transilation(store.description).getTranslationValue(i18n.language);
  return {
    socialLinks: store.socialLinks,
    description: description(),
    name: name(),
    //categories: getCategories(),
    logo: store.logo.url,
    isActive: store.isActive,
  };
};

export default useStore;
