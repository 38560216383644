import { WebsiteContext } from 'context';
import _ from 'lodash';
import { useContext, useState } from 'react';
import transilation from 'hooks/name';
import { useTranslation } from 'react-i18next';
const useCategoires = () => {
  const { categories, setSelectedCategoryId, selectedCategoryId } =
    useContext(WebsiteContext);
  const [t, i18n] = useTranslation();
  const lang = i18n.language;
  // categories
  const handleCategoryClick = (categoryId: string) => {
    let newCateogryId = '';
    if (selectedCategoryId !== categoryId) {
      newCateogryId = categoryId;
    }
    setSelectedCategoryId(newCateogryId);
  };
  const isCategorySelected = (categoryId: string) => {
    return categoryId === selectedCategoryId;
  };
  const getCategories = () => {
    let categoryTest = categories.map((category) => {
      const name = transilation(category.name).getTranslationValue(lang);
      return {
        id: category.id,
        name: name,
      };
    });
    return categoryTest;
  };
  return {
    categories: getCategories(),
    isCategorySelected,
    handleCategoryClick,
  };
};
export default useCategoires;
