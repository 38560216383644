import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { contacts } from 'constant/values';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
  button: {
    color: 'red',
  },
  icon: {
    //marginLeft: 10,
    marginRight: 10,
  },
}));

interface Props {
  children?: any;
  text?: string;
}

const ContactSupport = (props: Props): ReactElement => {
  const classes = useStyles();
  const { text = 'contact_support' } = props;
  const [t] = useTranslation();
  return (
    <Button
      href={contacts.whatsAppLink}
      variant="outlined"
      color="primary"
      component="a"
      target="blank"
      size="large"
      endIcon={<WhatsAppIcon className={classes.icon} />}
    >
      {t(text)}
    </Button>
  );
};

const defaultProps: Props = {};

ContactSupport.defaultProps = defaultProps;

export default ContactSupport;
