import React, { useState, useContext, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Footer from './components/footer';
import { Item } from 'interfaces';
import { useItems, useStore } from 'hooks';
import CardItem from './components/card';
import { useTranslation } from 'react-i18next';
import Categories from './components/categoires';
import { ViewTypeContext, WebsiteContext } from 'context';
import { ScrollTop, SuspendedStore, ViewItem } from 'components';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    // height: '22rem',
    // width: '20rem',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '80%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardDescription: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  storeLogo: {
    width: '10rem',
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2.5),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  categories: {
    //backgroundColor: 'red',
    minHeight: 90,
  },
  category: {
    fontWeight: 'bold',
    borderRadius: 10,
    minHeight: 40,
  },
  categoryRTL: {
    marginLeft: theme.spacing(3),
  },
  categoryLTR: {
    marginRight: theme.spacing(3),
  },
  categoiresWithLargeScreen: {
    marginBottom: theme.spacing(2),
  },
  categoryWithLargeScreen: {
    fontWeight: 'bold',
  },
  name: {
    fontWeight: 400,
  },
}));

const Home = (props: any) => {
  const classes = useStyles();
  const { name, description, logo, isActive } = useStore();
  const { selectedCategoryId } = useContext(WebsiteContext);
  const { items } = useItems();
  const [t, i18n] = useTranslation();
  const dir = i18n.dir();
  const [openedItem, setOpenedItem] = useState<Item | undefined>(undefined);
  const [animate, setAnimate] = useState(false);
  const viewType = useContext(ViewTypeContext);
  useEffect(() => {
    setAnimate(true);
  }, [selectedCategoryId]);
  // const ItemsAndCategories = () => {
  //   return (
  //     <>
  //       <Categories />
  //       <Grid
  //         container
  //         spacing={viewType === 'small' ? 2 : 4}
  //         justifyContent="center"
  //       >
  //         {items.map((card: Item) => (
  //           <Grid
  //             item
  //             key={card.id}
  //             xs={viewType === 'small' ? 6 : 12}
  //             sm={viewType === 'small' ? 3 : 6}
  //             md={viewType === 'small' ? 2 : 4}
  //           >
  //             <CardItem
  //               card={card}
  //               onClick={setOpenedItem}
  //               viewType={viewType}
  //             />
  //           </Grid>
  //         ))}
  //       </Grid>
  //     </>
  //   );
  // };
  return (
    <React.Fragment>
      <main>
        <ViewItem item={openedItem} onClose={() => setOpenedItem(undefined)} />
        {/* <ScrollTop /> */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <div className={classes.header}>
              <img src={logo} className={classes.storeLogo} />
              <Typography
                variant="h2"
                align="center"
                color="textPrimary"
                className={classes.name}
                gutterBottom
              >
                {name}
              </Typography>
            </div>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              {description}
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="lg">
          <div dir={dir}>
            {isActive ? (
              <>
                <Categories />
                <Grid
                  container
                  spacing={viewType === 'small' ? 2 : 4}
                  justifyContent="center"
                >
                  {items.map((card: Item) => (
                    <Grid
                      item
                      key={card.id}
                      xs={viewType === 'small' ? 6 : 12}
                      sm={viewType === 'small' ? 3 : 6}
                      md={viewType === 'small' ? 2 : 4}
                    >
                      <CardItem
                        card={card}
                        onClick={setOpenedItem}
                        viewType={viewType}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <SuspendedStore />
            )}
          </div>
        </Container>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Home;
