export default {
  resources: {
    en: {
      translation: {
        Foodlee: 'Foodlee',
        helloWorld: 'Hello World',
        copyright: 'Copyright',
        website: 'Your Website',
        calories: 'CAL',
        store_suspended:
          'Your Foodlee account has been suspended ... please contact our support team',
        contact_support: 'Contact support',
      },
    },
    ar: {
      translation: {
        store_suspended:
          'عزيزنا التاجر، عذراً تم ايقاف حسابكم في فودلي ... الرجاء التواصل مع فريق الدعم',
        contact_support: 'تواصل مع الدعم الفني',
        helloWorld: 'Hallo Welt',
        calories: 'السعرات',
        Foodlee: 'فودلي',
      },
    },
  },
  lng: 'ar',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
};
