import produce from 'immer';
import { Theme } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { createWebsiteTheme } from 'theme';
import { Theme as MUIThemeProps } from '@material-ui/core/styles';
import _ from 'lodash';
const useTheme = (storeTheme: Theme) => {
  const savedMode = localStorage.getItem('themeMode') || 'dark';
  const [isDark, setIsDark] = useState(savedMode === 'dark');
  const storePrimaryColor = storeTheme.palette.light?.primary?.main || '';

  const getTheme = (type: 'light' | 'dark'): MUIThemeProps => {
    return createWebsiteTheme({ type, main: storePrimaryColor });
  };

  const [darkTheme, setDarkTheme] = useState(getTheme('dark'));
  const [lightTheme, setLightTheme] = useState(getTheme('light'));
  const [theme, setTheme] = useState(isDark ? darkTheme : lightTheme);
  useEffect(() => {
    const dark = getTheme('dark');
    const light = getTheme('light');
    setDarkTheme(dark);
    setLightTheme(light);
    if (isDark) {
      setTheme(dark);
    } else {
      setTheme(light);
    }
  }, [storePrimaryColor]);
  const toggleTheme = () => {
    let mode = '';
    setIsDark(!isDark);
    if (!isDark === true) {
      mode = 'dark';
      setTheme(darkTheme);
    } else {
      mode = 'light';
      setTheme(lightTheme);
    }
    localStorage.setItem('themeMode', mode);
  };

  return {
    toggleTheme,
    theme,
    isDark,
  };
};
export default useTheme;
