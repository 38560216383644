import { Cloudinary } from '@cloudinary/url-gen';
import { limitFit } from '@cloudinary/url-gen/actions/resize';

export const getCloudinaryImageURL = (public_id: string | undefined) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_cloud_name,
    },
  });
  if (undefined) return '';
  const myImage = cld.image(public_id);

  // Resize to 250 x 250 pixels using the 'fill' crop mode.
  //myImage.resize(fit().width(1000).height(1000)).format('auto');
  //myImage.resize(fit().width(1000).height(1000));

  myImage.resize(limitFit().width(1000).height(1000)).format('auto');
  return myImage.toURL();
};
