import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Item } from 'interfaces';
import CustomDialog from './custom-dialog';
import { useName } from 'hooks';
import { useTranslation } from 'react-i18next';
import { priceFormatter } from 'utils';
import { TextField } from '@material-ui/core';
import { getCloudinaryImageURL } from 'utils/image';
import { getDeviceInfo } from 'utils/device';

const useStyles = makeStyles((theme) => ({
  root: {
    //margin: theme.spacing(2),
  },
  price: {
    fontWeight: 700,
  },
  content: {},
  img: {
    borderRadius: 10,
    //borderRadius: 3,
    width: '100%',
  },
}));

interface Props {
  children?: any;
  item: Item | undefined;
  onClose: () => void;
}

const ViewItem = (props: Props): ReactElement => {
  const { isPhone } = getDeviceInfo();
  const classes = useStyles();
  const { item, onClose } = props;
  const [open, setOpen] = useState(false);
  const phoneHeight = 250;
  const desktopHeight = 300;
  const [height, setHeight] = useState<any>(
    isPhone ? phoneHeight : desktopHeight
  );
  const [t, i18n] = useTranslation();
  useEffect(() => {
    if (item !== undefined) setOpen(true);
  }, [item]);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const lang = i18n.language;
  const name = useName(item?.name).getTranslationValue(lang);
  const discreption = useName(item?.description).getTranslationValue(lang);
  console.log('discreption', discreption);
  const price = item?.price;
  const currency = item?.currency;
  const src = getCloudinaryImageURL(item?.images[0]?.public_id);
  const alt = item?.images[0]?.alt;
  const hasPrice = price || 0 > 0;
  const Calories = () => {
    return (
      <Typography variant="caption">
        {t('calories')}: {item?.information.calories}
      </Typography>
    );
  };
  const Price = () => {
    if (!hasPrice) return null;

    return (
      <Grid item>
        <Typography className={classes.price} variant="body1">
          {currency} {price}
        </Typography>
      </Grid>
    );
  };
  return (
    <CustomDialog fullScreen={isPhone} open={open} onClose={handleClose}>
      <Grid
        container
        spacing={2}
        className={classes.root}
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <img
            // style={{
            //   width: '100%',
            // }}
            className={classes.img}
            src={src}
            alt={alt}
          />
        </Grid>
        <Grid item>
          <Typography variant="h6">{name}</Typography>
        </Grid>
        <Price />
        <Grid item xs={12}>
          <Typography variant="body1">
            <pre
              style={{
                fontFamily: 'inherit',
                width: '100%',
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                //textAlign: 'center',
              }}
            >
              {discreption}
            </pre>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Calories />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            helperText="Hight"
            value={height}
            onChange={(e) => setHeight(Number(e.target.value))}
          />
        </Grid> */}
      </Grid>
    </CustomDialog>
  );
};

export default ViewItem;
