import languageIdentifier from 'language-identifier';
import _ from 'lodash';

const easyMode = (arabicScore, englishScore) => {
  if (arabicScore >= 1) {
    return response('ar', 'arabic');
  } else {
    return response('en', 'english');
  }
};

const strictMode = (arabicScore, englishScore) => {
  if (arabicScore > englishScore) {
    return response('ar', 'arabic');
  } else {
    return response('en', 'english');
  }
};

const response = (languageCode, language) => {
  return { languageCode, language };
};

export const detectLanguage = (text, mode = 'strict') => {
  let splitedText = text.trim().split('');

  let arabicScore = 0;
  let englishScore = 0;

  splitedText.map((car) => {
    const result = languageIdentifier.identify(car);

    if (result === undefined) return;
    let isArabic = result.includes('Arabic');
    let isEnglish = result.includes('English');
    if (isArabic) arabicScore++;
    if (isEnglish) englishScore++;
  });

  switch (mode) {
    case 'strict':
      return strictMode(arabicScore, englishScore);
    default:
      return easyMode(arabicScore, englishScore);
  }
};

export const newTranslation = (value, targetLanguage = false) => {
  const language = targetLanguage
    ? languageSymbolOrName(targetLanguage)
    : detectLanguage(value);
  return {
    ...language,
    translation: value.toString().trim(),
  };
};

export const convertDefaultToTransiltion = (translationInfo) => {
  const { languageCode } = translationInfo.defaultLanguage;
  return newTranslation(translationInfo.default, languageCode);
};

export const changeDefaultValue = (translationInfo, chosenLanguage) => {
  const { translations, defaultLanguage } = translationInfo;
  const findTranslation = translations.find(
    ({ languageCode }) => languageCode === chosenLanguage
  );
  if (chosenLanguage !== defaultLanguage.languageCode) {
    if (findTranslation) {
      const newDefaultValue = findTranslation.translation;
      const filteredTranslations = translations.filter(
        ({ languageCode }) => languageCode !== chosenLanguage
      );
      return {
        default: newDefaultValue,
        defaultLanguage: languageSymbolOrName(chosenLanguage),
        translations: [
          ...filteredTranslations,
          convertDefaultToTransiltion(translationInfo),
        ],
      };
    } else {
      return translationInfo;
    }
  } else {
    return translationInfo;
  }
};

export const languageSymbolOrName = (langugaeOrSymbol) => {
  switch (langugaeOrSymbol) {
    case 'ar':
    case 'arabic':
      return response('ar', 'arabic');

    case 'en':
    case 'english':
      return response('en', 'english');
    default:
      response(false, false);
  }
};

export const getTranslation = (
  translationObject,
  language = 'default',
  mustRetrunValue = true
) => {
  const { translations } = translationObject;

  const defaultTranslation = translationObject.default;
  if (language === 'default') return defaultTranslation;

  const targetLanguage = languageSymbolOrName(language).language;
  const defaultLanguage = translationObject.defaultLanguage.language;

  const value = translations.find(
    (translation) => translation.language === targetLanguage
  );

  if (value) {
    if (value.translation !== '') return value.translation;
    return defaultTranslation;
  }

  if (defaultLanguage === targetLanguage) {
    if (_.isEmpty(defaultTranslation) === false) return defaultTranslation;
    if (translations[0]) return translations[0].translation;
    return '';
  }

  if (mustRetrunValue) return defaultTranslation;

  return '';
};

export const changeTranslation = (
  translationObject,
  value = '',
  language = false
) => {
  const defaultLanguage = translationObject.defaultLanguage.language;
  const targetLanguage =
    language === false ? detectLanguage(value) : languageSymbolOrName(language);

  if (defaultLanguage === targetLanguage.language || defaultLanguage === '') {
    translationObject.default = value;
    translationObject.defaultLanguage = targetLanguage;
  } else {
    // edit translation
    const editTranslation = (translations) => {
      if (translations.length > 0) {
        return translations.filter((possibleTranslation) => {
          const { language } = possibleTranslation;

          if (language === targetLanguage.language) {
            if (value.trim() !== '') {
              possibleTranslation.translation = value;
              return possibleTranslation;
            }
          } else {
            return possibleTranslation;
          }
        });
      } else {
        if (value.trim() === '') {
          return [];
        } else {
          return [newTranslation(value, targetLanguage.language)];
        }
      }
    };

    translationObject.translations = editTranslation(
      translationObject.translations
    );
  }
  const defaulValue = translationObject.default.trim();
  if (defaulValue === '') {
    translationObject.defaultLanguage.language = '';
    translationObject.defaultLanguage.languageCode = '';
    const translations = translationObject.translations;
    if (translations.length > 0) {
      const firstTransliation = translations[0];
      translationObject.defaultLanguage.language = firstTransliation.language;
      translationObject.defaultLanguage.languageCode =
        firstTransliation.languageCode;
      translationObject.default = firstTransliation.translation;

      _.pullAt(translationObject.translations, 0);
    }
  }
  return translationObject;
};
