const wh = (width: number, height: number) => {
  return { width, height };
};
export const imageSmall = {
  thumb: wh(172, 150),
};
export const imageMed = {
  thumb: wh(345, 200),
  // the good highet for to see three items per row is 200 height for
};

export const imageLarge = {
  thumb: wh(345, 230),
  //it was 275
  // 250 is goog also
};
