import React, { ReactElement } from 'react';
import { iconList, IconSize, IconColors } from 'components/props';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Menu from '@material-ui/icons/Menu';
import Notifications from '@material-ui/icons/Notifications';
import Fastfood from '@material-ui/icons/Fastfood';
import CategoryOutlined from '@material-ui/icons/CategoryOutlined';
import Add from '@material-ui/icons/Add';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Storefront from '@material-ui/icons/Storefront';
import Settings from '@material-ui/icons/Settings';
import Dashboard from '@material-ui/icons/Dashboard';
import Fire from '@material-ui/icons/WhatshotOutlined';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

interface Props {
  icon: iconList;
  fontSize?: IconSize;
  className?: any;
  color?: IconColors;
}

const Icon = (props: Props): ReactElement => {
  const { icon, ...rest } = props;

  switch (icon) {
    case 'right':
      return <KeyboardArrowRightIcon {...rest} />;
    case 'left':
      return <KeyboardArrowLeftIcon {...rest} />;
    case 'Storefront':
      return <Storefront {...rest} />;

    case 'Settings':
      return <Settings {...rest} />;

    case 'Dashboard':
      return <Dashboard {...rest} />;

    case 'CategoryOutlined':
      return <CategoryOutlined {...rest} />;

    case 'Fastfood':
      return <Fastfood {...rest} />;

    case 'Add':
      return <Add {...rest} />;

    case 'Menu':
      return <Menu {...rest} />;

    case 'ChevronLeft':
      return <ChevronLeft {...rest} />;

    case 'Notifications':
      return <Notifications {...rest} />;
    case 'fire':
      return <Fire {...rest} />;
    case 'PhotoCamera':
      return <PhotoCamera {...rest} />;
  }
};

export default Icon;
