import { createContext } from 'react';
import { defaultWebsite } from 'validations/default-values';
const defaultWebsiteContext = {
  ...defaultWebsite,
  selectedCategoryId: '',
  setSelectedCategoryId: (categoryId: string) => {},
};
export const WebsiteContext = createContext(defaultWebsiteContext);
export const UserContext = createContext({ isLoggedIn: false });
export const ViewTypeContext = createContext<'default' | 'small'>('default');
