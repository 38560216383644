import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ContactSupport from 'components/contact-support';
import * as emoji from 'node-emoji';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {},
}));

interface Props {
  children?: any;
}

const SuspendedStore = (props: Props): ReactElement => {
  const classes = useStyles();
  const {} = props;
  const [t] = useTranslation();
  const heartEmoji = emoji.get('heart');

  return (
    <Grid
      className={classes.root}
      container
      justifyContent="center"
      direction="column"
      alignContent="center"
      alignItems="center"
      spacing={5}
    >
      <Grid className={classes.item} item>
        <Typography variant="subtitle1" align="center">
          {' '}
          {t('store_suspended')} {heartEmoji}
        </Typography>
      </Grid>
      <Grid item>
        <ContactSupport />
      </Grid>
    </Grid>
  );
};

const defaultProps: Props = {};

SuspendedStore.defaultProps = defaultProps;

export default SuspendedStore;
