import { createTimestamp } from 'utils/dates';
import { subscriptionPlans } from 'constant/values/common';
import { SelectedSubscription } from 'interfaces/subscription';
const basicPlan = subscriptionPlans.basic;

export const subscription: SelectedSubscription = {
  membership: 'basic',
  startedAt: createTimestamp(),
  expiredAt: 0,
  isForever: true,
  duration: 'monthly',
};
export const identifiers = [];
