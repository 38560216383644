import React from 'react';
import ReactDOM from 'react-dom';
import './styles/root.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserContext } from 'context';
import firebase from 'firebase-app';
import '@fontsource/dancing-script';
import '@fontsource/kirang-haerang';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { version } from '../package.json';
import { getStoreIdFromURL } from 'services/store/utils';
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [new BrowserTracing(), new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  environment: process.env.REACT_APP_ENV,
  replaysOnErrorSampleRate: 1,
  release: version,
});
firebase.auth().onAuthStateChanged((user) => {
  const isLoggedIn = user ? true : false;
  Sentry.setTag('storeId', getStoreIdFromURL() || 'no_store_id');
  return ReactDOM.render(
    <UserContext.Provider value={{ isLoggedIn }}>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </UserContext.Provider>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
