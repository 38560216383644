import { createTimestamp } from 'utils/dates';
import selectedStore from '../store-selected';
import { name, description } from 'validations/default-values';
import { Item } from 'interfaces';
import store from '../store';

const item: Item = {
  id: '',
  name: name,
  currency: 'SAR',
  description: description,
  rating: 0,
  price: 0,
  store: store,
  images: [],
  createdAt: createTimestamp(),
  modifiedAt: createTimestamp(),
  isLive: false,
  identifiers: [],
  categories: [],
  categoryIndex: -1,
  itemIndex: -1,
  keywords: [],
  addons: [],
  information: {
    calories: 0,
  },
  variants: [],
  websiteConfig: {},
};

export default item;
