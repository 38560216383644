import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';

import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Grid } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { getDeviceInfo } from 'utils/device';
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  root: {
    //height: '100%',
    //height: '90%',
    //margin: theme.spacing(-3),
    //backgroundColor: 'black',
  },
  content: {
    //margin: theme.spacing(-3),
  },
  close: {
    marginBottom: theme.spacing(4),
  },
  dialog: {
    borderRadius: 20,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  actions: {
    justifyContent: 'center',
  },
}));

const Transition = React.forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Props {
  open: boolean;
  onClose: () => void;
  children: any;
  fullScreen?: boolean;
}
const CustomDialog = (props: Props) => {
  const { open, onClose, children, fullScreen } = props;
  const { isDesktop } = getDeviceInfo();
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      className={classes.root}
      //@ts-ignore
      TransitionComponent={Transition}
    >
      <DialogContent className={classes.content} dir={i18n.dir()}>
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <IconButton
          size="medium"
          //className={classes.close}
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <KeyboardArrowDownIcon fontSize="large" />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
