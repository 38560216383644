import { makeStyles, Typography } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
import { SocialLinksList } from 'interfaces/common';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  mediaLinks: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    margin: '0 8px',
    fontSize: '2rem',
  },
  copyRight: {
    marginTop: theme.spacing(1),
  },
}));

function Copyright() {
  const [t, i18n] = useTranslation();
  const lang = i18n.language;
  const classes = useStyles();
  const url =
    lang === 'ar' ? 'https://home.foodlee.io/ar' : 'https://home.foodlee.io';
  return (
    <Typography
      className={classes.copyRight}
      variant="body2"
      color="textSecondary"
      align="center"
    >
      {' © '}
      <Link target="_blank" color="inherit" href={url}>
        {t('Foodlee')}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Icon(props: { url: string | undefined; social: SocialLinksList }) {
  const classes = useStyles();
  const SnapIcon = () => {
    return (
      <SvgIcon className={classes.link} color="primary">
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.126 16.475c-.057.077-.103.4-.178.655-.086.295-.356.262-.656.203-.437-.085-.827-.109-1.281-.034-.785.131-1.601 1.292-2.969 1.292-1.472 0-2.238-1.156-3.054-1.292-.832-.138-1.31.084-1.597.084-.221 0-.307-.135-.34-.247-.074-.251-.12-.581-.178-.66-.565-.087-1.84-.309-1.873-.878-.008-.148.096-.279.243-.303 1.872-.308 3.063-2.419 2.869-2.877-.138-.325-.735-.442-.986-.541-.648-.256-.739-.55-.7-.752.053-.28.395-.468.68-.468.275 0 .76.367 1.138.158-.055-.982-.194-2.387.156-3.171.667-1.496 2.129-2.236 3.592-2.236 1.473 0 2.946.75 3.608 2.235.349.783.212 2.181.156 3.172.357.197.799-.167 1.107-.167.302 0 .712.204.719.545.005.267-.233.497-.708.684-.255.101-.848.217-.986.541-.198.468 1.03 2.573 2.869 2.876.146.024.251.154.243.303-.033.569-1.314.791-1.874.878z" />
      </SvgIcon>
    );
  };
  const { social, url } = props;
  let customUrl = url;
  if (url === undefined) return null;
  let currnetIcon = null;

  if (social === 'facebook') {
    currnetIcon = <FacebookIcon className={classes.link} color="primary" />;
  }

  if (social === 'whatsapp') {
    const phone = url;
    let whatsappUrl = `https://wa.me/${phone}`;
    customUrl = whatsappUrl;
    currnetIcon = <WhatsAppIcon className={classes.link} color="primary" />;
  }

  if (social === 'twitter') {
    currnetIcon = <TwitterIcon className={classes.link} color="primary" />;
  }

  if (social === 'instagram') {
    currnetIcon = <InstagramIcon className={classes.link} color="primary" />;
  }

  if (social === 'linkedin') {
    currnetIcon = <LinkedInIcon className={classes.link} color="primary" />;
  }

  if (social === 'snapchat') {
    currnetIcon = <SnapIcon />;
  }
  return (
    <Link target="_blank" color="inherit" href={customUrl}>
      {currnetIcon}
    </Link>
  );
}
function Footer() {
  const { socialLinks } = useStore();
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.mediaLinks}>
        {/* <Link color="inherit" href="">
          <TwitterIcon className={classes.link} color="primary" />
        </Link>
        <Link color="inherit" href="">
          <InstagramIcon className={classes.link} color="primary" />
        </Link> */}
        <Icon social="whatsapp" url={socialLinks.whatsapp} />
        <Icon social="instagram" url={socialLinks.instagram} />
        <Icon social="twitter" url={socialLinks.twitter} />
        <Icon social="facebook" url={socialLinks.facebook} />
        <Icon social="linkedin" url={socialLinks.linkedin} />
        <Icon social="snapchat" url={socialLinks.snapchat} />
      </div>
      <Copyright />
    </footer>
  );
}

export default Footer;
