import { WebsiteContext } from 'context';
import _ from 'lodash';
import { useContext, useState, useEffect } from 'react';
const useItems = () => {
  const { items, selectedCategoryId } = useContext(WebsiteContext);
  const [itemsToDisplay, setItemsToDisplay] = useState(items);
  useEffect(() => {
    setItemsToDisplay(getItems());
  }, [selectedCategoryId, items]);
  const getItems = () => {
    if (selectedCategoryId !== '') {
      let categoryItems = [];
      if (selectedCategoryId === 'other') {
        categoryItems = items.filter((item) => item.categories.length === 0);
      } else {
        categoryItems = _.filter(items, {
          categories: [{ id: selectedCategoryId }],
        });
      }
      return categoryItems;
    } else {
      return items;
    }
  };
  return {
    items: itemsToDisplay,
  };
};
export default useItems;
