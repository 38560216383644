import { createTheme } from '@material-ui/core/styles';
import _ from 'lodash';
const baseTheme = createTheme({
  //@ts-ignore
  typography: {
    //fontFamily: "'Tajawal', sans-serif",
    fontFamily: "'Noto Kufi Arabic', sans-serif",
    // fontFamily: "'Noto Naskh Arabic', sans-serif",
    //fontFamily: "'Cairo', sans-serif",

    fontSize: 14,
  },
  overrides: {
    MuiCard: {
      root: {},
    },
  },
});
export const defaultPrimaryColor = '#4791db';
const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    type: 'dark',
    primary: {
      main: defaultPrimaryColor,
    },
    secondary: {
      main: '#fafafa',
    },
  },
});
const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    type: 'light',
    primary: {
      main: defaultPrimaryColor,
    },
    secondary: {
      main: '#fafafa',
    },
  },
});
type themeType = 'light' | 'dark';

export const createWebsiteTheme = (options: {
  type: themeType;
  main: string;
}) => {
  const { main, type } = options;
  return createTheme({
    ...baseTheme,
    palette: {
      type: type,
      primary: {
        main: _.isEmpty(main) ? defaultPrimaryColor : main,
      },
      secondary: {
        main: '#fafafa',
      },
    },
  });
};
export { darkTheme, lightTheme };
