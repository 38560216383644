import { ReactElement } from 'react';

import {
  Image,
  Transformation,
  CloudinaryContext,
  //@ts-ignore
} from 'cloudinary-react';

interface Props {
  width: number | string;
  height: number | string;
  public_Id: string;
  crop:
    | 'fit'
    | 'fill'
    | 'scale'
    | 'lfill'
    | 'thumb'
    | 'crop'
    | 'imagga_crop'
    | 'fill_pad'
    | 'limit'
    | 'mfit';
  gravity: 'center' | 'auto' | 'face' | 'liquid';
  quality: string;
  fetchFormat: 'auto';
  dpr: 'auto';
}

const CloudinaryImage = (props: Props): ReactElement => {
  const { width, height, public_Id, crop, gravity, dpr, quality, fetchFormat } =
    props;
  const { REACT_APP_cloud_name } = process.env;
  return (
    <CloudinaryContext secure="true" cloudName={REACT_APP_cloud_name}>
      <Image publicId={public_Id} loading="lazy">
        <Transformation
          width={'500'}
          height={'500'}
          crop={crop}
          gravity={gravity}
        />
        <Transformation quality={quality} />
        <Transformation fetchFormat={fetchFormat} />

        {/* <Transformation
          gravity={gravity}
          width={width}
          height={height}
          quality={quality}
          crop={crop}
          //dpr={dpr}
          fetchFormat={fetchFormat}
        /> */}
      </Image>
    </CloudinaryContext>
  );
};
const defaultProps: Partial<Props> = {
  width: 'auto',
  crop: 'fit',
  //gravity: 'center',

  quality: '100',
  fetchFormat: 'auto',
  dpr: 'auto',
};
CloudinaryImage.defaultProps = defaultProps;
export default CloudinaryImage;
